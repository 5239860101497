import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MyAddress } from 'src/models/address.models';
import { AuthResponse } from 'src/models/auth-response.models';
import { Category } from 'src/models/category.models';
import { Helper } from 'src/models/helper.models';
import { Profile } from 'src/models/profile.models';
import { UiElementsService } from '../services/common/ui-elements.service';
import { MyEventsService } from '../services/events/my-events.service';
import { ApiService } from '../services/network/api.service';
import { GoogleMapsService } from '../services/network/google-maps.service';
import { FeedbackMatter } from 'src/models/feedback-matter.models';
import { BankDetail } from 'src/models/bank-detail.models';
import { PayoutRequest } from 'src/models/payout-request.models';
import html2canvas from 'html2canvas';
import { AppConfig, APP_CONFIG } from '../app.config';


declare var $: any;
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  @ViewChild('qr_container') qr_container: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  profile = Profile.getDefault();
  fresh_profile: boolean;
  private authRes: AuthResponse;
  private subscriptions = new Array<Subscription>();
  categoriesSelectedStrings = new Array<string>();
  categoriesSelectedStringsFormatted: string;
  categoryStrings = new Array<string>();
  private categories: Array<Category>;
  feedbackMatter: FeedbackMatter;
  payourRequest = new PayoutRequest();
  qrValue: string;

  @ViewChild("pleaseConnect", { static: true }) pleaseConnect: ElementRef;
  @ViewChild("map", { static: true }) mapElement: ElementRef;
  private autocompleteService: any;
  private placesService: any;
  private initialized: boolean;
  private marker: google.maps.Marker;
  private detecting = false;
  query = "";
  places = [];

  networkConfig = { host: "", port: "", name: "" };

  constructor(private translate: TranslateService, private router: Router, private myEvents: MyEventsService,
    public apiService: ApiService, private uiElementService: UiElementsService,
    @Inject(APP_CONFIG) public config: AppConfig, private ngZone: NgZone, private maps: GoogleMapsService) {
    // setTimeout(() => {
    //   this.profile.image_urls = ["https://previews.123rf.com/images/profotokris/profotokris1502/profotokris150200026/36633512-ripe-vegetables-and-fruits-organic-produce-square-composition-.jpg"];
    // }, 2000);
  }

  ngOnInit() {
    let savedNetworkConfig = Helper.getPrinterNetworkConfig();
    if (savedNetworkConfig) this.networkConfig = savedNetworkConfig;

    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state) this.fresh_profile = this.router.getCurrentNavigation().extras.state.fresh_profile;

    this.profile = Profile.getDefault();
    this.translate.get(["just_moment", "something_wrong"]).subscribe(values => {
      this.uiElementService.presentLoading(values["just_moment"]);
      this.subscriptions.push(this.apiService.getProfile().subscribe(resProfile => {
        this.profile = resProfile;
        setTimeout(() => this.profile = resProfile, 600);

        this.uiElementService.dismissLoading();
        this.getCategoriesStore();
        this.getFeedbackMatter();
        this.loadGoogleMap();
        console.log(this);
      }, err => {
        console.log("getProfile", err);
        this.uiElementService.dismissLoading();
        this.uiElementService.showErrorToastr(values["something_wrong"], "top");
        // this.navCtrl.pop();
      }));
    });

    let bankDetail: BankDetail = Helper.getBankDetail();
    if (bankDetail) {
      this.payourRequest.bank_name = bankDetail.bank_name;
      this.payourRequest.bank_account_name = bankDetail.bank_account_name;
      this.payourRequest.bank_account_number = bankDetail.bank_account_number;
    }
  }

  getCategoriesStore() {
    this.subscriptions.push(this.apiService.getCategoriesParents().subscribe(res => {
      this.categories = res;
      let catsSelectedStrings = [], catsStrings = [];
      for (let cNew of this.categories) {
        catsStrings.push(cNew.title);
        if (this.profile != null) for (let cOld of this.profile.categories) if (cOld.id == cNew.id) { catsSelectedStrings.push(cNew.title); break; }
      }
      this.categoriesSelectedStrings = catsSelectedStrings;
      this.categoryStrings = catsStrings;
      console.log("categoryStrings: ", this.categoryStrings);
      console.log("categoriesSelectedStrings: ", this.categoriesSelectedStrings);
      this.setCategoriesText();
      this.uiElementService.dismissLoading();
    }, err => {
      console.log("getCategoriesParents", err);
      this.uiElementService.dismissLoading();
      // this.navCtrl.pop();
    }));
  }

  getFeedbackMatter() {
    this.subscriptions.push(this.apiService.getFeedbackMatter(this.profile.id).subscribe(res => { console.log("res: ", res); this.setupFeedbackMatter(res); }, err => { console.log("err: ", err); this.setupFeedbackMatter(null); }));
  }

  setupFeedbackMatter(fmIn: FeedbackMatter) {
    let defFm = FeedbackMatter.defaultMatter();
    if (!this.feedbackMatter) this.feedbackMatter = fmIn ? fmIn : defFm;
    for (let i = this.feedbackMatter.questions.length; i < 4; i++) this.feedbackMatter.questions.push(defFm.questions[i]);
    for (let i = this.feedbackMatter.options.length; i < 4; i++) this.feedbackMatter.options.push(defFm.options[i]);
  }

  private setCategoriesText() {
    this.categoriesSelectedStringsFormatted = "";
    for (let ct of this.categoriesSelectedStrings) this.categoriesSelectedStringsFormatted += (ct + ", ");
    if (this.categoriesSelectedStringsFormatted.length) this.categoriesSelectedStringsFormatted = this.categoriesSelectedStringsFormatted.substring(0, this.categoriesSelectedStringsFormatted.length - 2);
  }

  onCategoriesChange() {
    this.setCategoriesText();
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) sub.unsubscribe();
    this.uiElementService.dismissLoading();
  }

  loadGoogleMap() {
    if (!this.initialized) {
      let initialLocation: MyAddress;
      if (this.profile && this.profile.latitude && this.profile.longitude) {
        initialLocation = new MyAddress();
        initialLocation.latitude = this.profile.latitude;
        initialLocation.longitude = this.profile.longitude;
        initialLocation.formatted_address = this.profile.address;
      }

      let mapLoaded = this.maps.init(this.mapElement.nativeElement, this.pleaseConnect.nativeElement, initialLocation).then(() => {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(this.maps.map);
        this.maps.map.addListener('click', (event) => {
          if (event && event.latLng) {
            let pos = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
            this.geocode(pos);
          }
        });
        this.initialized = true;
        if (this.profile && this.profile.latitude && this.profile.longitude) {
          let pos = new google.maps.LatLng(Number(this.profile.latitude), Number(this.profile.longitude));
          this.plotMarker(pos);
        } else {
          this.detect();
        }
      }).catch(err => this.close());
      mapLoaded.catch(err => this.close());
    }
  }

  searchPlace() {
    if (this.autocompleteService && this.query.length > 0) {
      let config = { input: this.query }
      this.autocompleteService.getPlacePredictions(config, (predictions, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK && predictions) {
          this.places = [];
          predictions.forEach((prediction) => this.places.push(prediction));
        }
      });
    } else {
      this.places = [];
    }
  }

  selectPlace(place) {
    this.query = place.description;
    this.places = [];
    let myLocation = new MyAddress();
    myLocation.id = -1;
    // if (this.location) {
    //   myLocation.id = this.location.id;
    //   myLocation.title = this.location.title;
    // }
    myLocation.formatted_address = place.name;
    this.placesService.getDetails({ placeId: place.place_id }, (details) => {
      this.ngZone.run(() => {
        let form_address = '';
        details.address_components.map(element => {
          if (element.types[0] != "administrative_area_level_2" && element.types[0] != "country" && element.types[0] != "postal_code" && element.types[0] != "administrative_area_level_1") {
            form_address = form_address ? form_address + ", " + element.long_name : element.long_name
          }
        })
        myLocation.formatted_address = form_address;
        // myLocation.formatted_address = (details.formatted_address && details.formatted_address.length) ? details.formatted_address : details.name;
        myLocation.latitude = String(details.geometry.location.lat());
        myLocation.longitude = String(details.geometry.location.lng());
        let lc = { lat: details.geometry.location.lat(), lng: details.geometry.location.lng() };
        this.maps.map.setCenter(lc);
        //this.location = myLocation;
        this.profile.address = myLocation.formatted_address;
        this.profile.latitude = myLocation.latitude;
        this.profile.longitude = myLocation.longitude;
        let pos = new google.maps.LatLng(Number(lc.lat), Number(lc.lng));
        this.plotMarker(pos);
      });
    });
  }

  close() {
    let newLocation: MyAddress = Helper.getLocationDefault();
    if (newLocation) {
      this.profile.address = newLocation.formatted_address;
      this.profile.latitude = newLocation.latitude;
      this.profile.longitude = newLocation.longitude;
    }
    Helper.setLocationDefault(null);
  }

  selectAddress(address: MyAddress) {
    Helper.setAddressSelected(address);
    this.close();
  }

  detect() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        this.geocode(pos);
        this.detecting = false;
      });
    }
  }

  geocode(pos: google.maps.LatLng) {
    let geocoder = new google.maps.Geocoder();
    let request = { location: pos };
    geocoder.geocode(request, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
        let myLocation = new MyAddress();
        myLocation.id = -1;
        // if (this.location) {
        //   myLocation.id = this.location.id;
        //   myLocation.title = this.location.title;
        // }
        let form_address = '';
        results[0].address_components.map(element => {
          if (element.types[0] != "administrative_area_level_2" && element.types[0] != "country" && element.types[0] != "postal_code" && element.types[0] != "administrative_area_level_1") {
            form_address = form_address ? form_address + ", " + element.long_name : element.long_name;
          }
        });
        myLocation.formatted_address = form_address;
        // myLocation.formatted_address = results[0].formatted_address;
        myLocation.latitude = String(pos.lat());
        myLocation.longitude = String(pos.lng());
        //this.location = myLocation;

        this.profile.address = myLocation.formatted_address;
        this.profile.latitude = myLocation.latitude;
        this.profile.longitude = myLocation.longitude;

        this.plotMarker(pos);
        this.uiElementService.showSuccessToastr(myLocation.formatted_address);
      }
    });
  }

  plotMarker(pos: google.maps.LatLng) {
    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: pos, map: this.maps.map
        //, icon: 'assets/images/marker_map_me.png'
      });
      this.marker.setClickable(true);
      this.marker.addListener('click', (event) => this.uiElementService.showSuccessToastr(this.profile.address));
    }
    else {
      this.marker.setPosition(pos);
    }
    this.maps.map.panTo(pos);
  }

  onCheckboxClick(checkBoxId) {
    var checkBox: any = document.getElementById(checkBoxId);
    if (checkBox != null) {
      switch (checkBoxId) {
        case "review_app":
          this.profile.meta.review_app = checkBox.checked;
          break;
        case "kitchecn_app":
          this.profile.meta.kitchecn_app = checkBox.checked;
          break;
        case "kiosk_app":
          this.profile.meta.kiosk_app = checkBox.checked;
          break;
        case "emenu_app":
          this.profile.meta.emenu_app = checkBox.checked;
          if (this.profile.meta.emenu_app && (!Number(this.profile.meta.no_of_tables) || Number(this.profile.meta.no_of_tables) < 1)) this.profile.meta.no_of_tables = 1;
          break;
        case "desk_app":
          this.profile.meta.desk_app = checkBox.checked;
          break;
        case "table_booking":
          this.profile.meta.table_booking = checkBox.checked;
          break;
      }
    }
  }

  update() {
    Helper.setPrinterNetworkConfig(this.networkConfig);

    Helper.setBankDetail({
      bank_name: this.payourRequest.bank_name,
      bank_account_name: this.payourRequest.bank_account_name,
      bank_account_number: this.payourRequest.bank_account_number
    });

    let questionsFilled = true;
    if (this.profile.meta.review_app) {
      for (let question of this.feedbackMatter.questions) {
        if (!question.title || !question.title.length) {
          questionsFilled = false;
          break;
        }
      }
      if (!questionsFilled) {
        this.translate.get("err_field_review_app_quesitons").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
        return;
      }
    } else {
      questionsFilled = false;
    }

    if (!this.profile.name || !this.profile.name.length) {
      this.translate.get("err_field_profile_name").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
    } else if (!this.categoriesSelectedStrings || !this.categoriesSelectedStrings.length) {
      this.translate.get("err_field_product_categories").subscribe(value => this.uiElementService.showErrorToastr(value));
    } else if (!this.profile.meta.green_time) {
      this.translate.get("green_time_text").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
    } else if (!this.profile.meta.yellow_time) {
      this.translate.get("yellow_time_text").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
    } else if (!this.profile.meta.red_time) {
      this.translate.get("red_time_text").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
    } else if (!this.profile.latitude || !this.profile.longitude || !this.profile.latitude.length || !this.profile.longitude.length || !this.profile.address || !this.profile.address.length) {
      this.translate.get("err_field_profile_location").subscribe(value => this.uiElementService.showErrorToastr(value, "toast-top-center"));
    } else {
      let catsSelected = new Array<number>();
      for (let cat of this.categories) if (this.categoriesSelectedStrings.includes(cat.title)) catsSelected.push(cat.id);
      // let color_setting = { green_time: this.apiService.times.find(key => key.key == this.profile.meta.green_time).value, yellow_time: this.apiService.times.find(key => key.key == this.profile.meta.yellow_time).value, red_time: this.apiService.times.find(key => key.key == this.profile.meta.red_time).value }
      this.profile.meta.no_of_tables = this.profile.meta.no_of_tables && this.profile.meta.no_of_tables > 0 ? this.profile.meta.no_of_tables : 0;

      let pur = Profile.getUpdateRequest(this.profile); pur.categories = catsSelected;

      if (!pur.availability || !pur.availability.length) {
        this.translate.get("err_field_profile_availability").subscribe(value => this.uiElementService.showErrorToastr(value));
      } else {
        this.translate.get(["updating", "something_wrong"]).subscribe(values => {
          this.uiElementService.presentLoading(values["updating"]);
          //this.subscriptions.push(this.apiService.createFeedbackMatter(this.profile.id, FeedbackMatter.getRequest(this.feedbackMatter)).subscribe(res => this.setupFeedbackMatter(res), err => console.log("createFeedbackMatter: ", err)));
          this.subscriptions.push(this.apiService.updateProfile(pur, this.profile.id).subscribe(res => {
            this.uiElementService.dismissLoading();
            if (this.authRes != null) {
              Helper.setLoggedInUserResponse(this.authRes);
            } else {
              Helper.setLoggedInUser(res.user);
            }
            this.myEvents.setProfileData(res);
          }, err => {
            console.log("updateProfile", err);
            this.uiElementService.dismissLoading();
          }));
        });
      }
    }
  }

  qrShow() {
    //this.qrValue = this.qrValue ? null : String(this.profile.id);
    if(this.profile.id == 26){
      this.qrValue = this.qrValue ? null : String('https://pizzaguysdarlington.com');
    }
    if(this.profile.id == 32){
      this.qrValue = this.qrValue ? null : String('https://athenanewcastle.co.uk/#/restaurant');
    }
    if(this.profile.id == 29){
      this.qrValue = this.qrValue ? null : String('https://pizzadaddy.uk/#/restaurant');
    }
    if(this.profile.id == 34){
      this.qrValue = this.qrValue ? null : String('https://salslivi.com/');
    }
    // if(this.profile.id ==){
    //   this.qrValue = this.qrValue ? null : String('https://pizzaguysdarlington.com');

    // }
    // if(this.profile.id ==){
    //   this.qrValue = this.qrValue ? null : String('');

    // }
    //this.qrValue = this.qrValue ? null : String('https://pizzaguysdarlington.com');

    if (this.qrValue) {
      this.canvas.nativeElement.removeAttribute("src");
      this.downloadLink.nativeElement.removeAttribute("href");
      this.downloadLink.nativeElement.removeAttribute("download");
    }
  }

  downloadImage() {
    html2canvas(this.qr_container.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'qr.png';
      this.downloadLink.nativeElement.click();

      this.qrValue = null;
    });
  }

}
